import axiosInstance from "../utils/axios";

export interface AppSettings {
    frontegg: {
        base_url: string,
        app_id: string,
        client_id: string
    }
}

export const getSettings = async (): Promise<AppSettings> => {
    const response = await axiosInstance.get("/system/settings");
    return response.data;
};

export interface SystemContext {
    name: string,
    description?: string,
}

export const getSystemContexts = async (): Promise<SystemContext[]> => {
    const response = await axiosInstance.get("/system/contexts");
    return response.data;
};

export const createSystemContext = async (name: string, description: string): Promise<SystemContext> => {
    const response = await axiosInstance.post(
        `/system/contexts/${name}`,
        {name, description});
    return response.data;
};

export const deleteSystemContext = async (name: string): Promise<number> => {
    const response = await axiosInstance.delete(`/system/contexts/${name}`);
    return response.data;
};