import {FormulaFeature} from "components/add-features-component/feature-types";
import axiosInstance from "../utils/axios";

export interface EntityResponse {
    id: string;
    name: string;
    description: string;
}

interface AssetFeaturePreviewOptions{
    entity_id: string,
    asset_id: string,
    filters?: {
        field: string,
        operator: string,
        data_type: string,
        values: any[],
    }[]
}

export enum TemplateType{
    field = "field",
    metric = "metric",
    firstLast = "first_last",
    formula = "formula"
}

export interface FirstLastTemplatePreviewOptions extends AssetFeaturePreviewOptions{
    sort_by: string,
    method: "first" | "last",
    fields: {field: string, name: string}[]
}

export interface MetricTemplatePreviewOptions extends AssetFeaturePreviewOptions{
    features: {sql: string, name: string}[]
}

export interface FieldTemplatePreviewOptions extends AssetFeaturePreviewOptions{
    features: {sql: string, name: string}[]
}

export interface FormulaTemplatePreviewOptions{
    entity_id: string,
    name: string,
    sql: string,
    features: FormulaFeature[]
}

export const preview = async (branch: string | null, type: TemplateType, options: FirstLastTemplatePreviewOptions | MetricTemplatePreviewOptions | FormulaTemplatePreviewOptions): Promise<{sql: string}> => {
    const params = branch ? {branch: branch} : null;
    const response = await axiosInstance.post(`/templates/preview/${type}`, options , {params});
    return response.data;
};
