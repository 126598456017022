import React, {useState} from 'react';
import {Box, Typography} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import theme from "../../theme";
import Tab from "@mui/material/Tab";
import {getTabProps} from "../shared/tabs/tabs-component";
import { Outlet } from 'react-router-dom';

const AdminComponent = () => {

    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const paddingTop = "32px"

    return (
        <Box sx={{display: "flex", marginTop: "-41px", width: "100%"}}>
            <Box sx={{
                boxSizing: "border-box",
                borderRight: `1px solid ${theme.palette.customColor.lightGrey1}`,
                height: "100%",
                width: "240px",
                flexShrink: 0,
                padding: `${paddingTop} 24px 0 0`,
            }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        color: theme.palette.customColor.dark,
                        marginBottom: "24px"
                    }}
                >
                    Admin center
                </Typography>
                <Tabs
                    orientation="vertical"
                    value={value}
                    onChange={handleChange}
                    aria-label="admin tabs"
                    sx={{
                        alignItems: "flex-start",
                        gap: "4px",
                        "& .MuiTab-root": {
                            justifyContent: "flex-start",
                            width: "100%",
                            borderRadius: "12px",
                            minHeight: "auto",
                            minWidth: "auto",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "24px",
                            padding: "10px 12px",
                        },
                        "& .MuiTabs-indicator": {
                            backgroundColor: "transparent",
                            width: 0,
                        },
                        "& .Mui-selected": {
                            color: `${theme.palette.customColor.purple} !important`,
                            backgroundColor: theme.palette.customColor.lightestGrey5,
                        },
                    }}
                >
                    <Tab
                        icon={<span className="material-symbols-outlined">person</span>}
                        iconPosition="start"
                        label="Account"
                        {...getTabProps(0)}
                    />
                </Tabs>
            </Box>

            <Box sx={{padding: `${paddingTop} 216px 0`, flexGrow:1}}>
                <Outlet />
            </Box>

        </Box>
    );
}

export default AdminComponent;