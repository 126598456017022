import React from "react";
import ButtonComponent from "./button.component";


interface CopyButtonProps {
    onClick: () => void;
    buttonState: { text: string; padding: string };
    sx?: object;
}

const CopyButton: React.FC<CopyButtonProps> = ({ onClick, buttonState, sx }) => {
    return (
        <ButtonComponent
            label={buttonState.text}
            onClick={onClick}
            variant={"text"}
            sx={{
                ...sx,
                border: 0,
                position: "absolute",
                fontSize: "14px",
                lineHeight: "22px",
            }}
            startIcon={<span className="material-symbols-outlined">content_copy</span>}
        />
    );
};

export default CopyButton;