import React, { useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { DataVirtual } from "assets/icons";
import { getDataAssetTitle } from "../../data-asset-name";
import theme from "theme";
import "./add-features-modal-data.scss";
import { useGlobalContext } from "context/global-context";
import {EntityDetailResponse, getEntity as getEntityApi} from "../../../../services/entities";

import pluralize from "pluralize";

export enum RelatedAssetsListEntitiesRelationFilter{
    AnyToMany="AnyToMany",
    AnyToOne="AnyToOne"
}

interface RelatedAssetsListProps {
    entityId: string;
    filter: string;
    relatedEntitiesRelationFilter?: RelatedAssetsListEntitiesRelationFilter,
    onSelectAsset: (assetId: string) => void;
}

const RelatedAssetsList: React.FC<RelatedAssetsListProps> = ({
    filter,
    entityId,
    relatedEntitiesRelationFilter,
    onSelectAsset,
}) => {
    const [relatedEntities, setRelatedEntities] = React.useState<{entity: string, relation:string}[]>([]);
    const [relatedAssets, setRelatedAssets] = React.useState<string[]>([]);
    const [entity, setEntity] = React.useState<EntityDetailResponse | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const {selectedGitBranch, isEditMode} = useGlobalContext();
    useEffect(()=>{
        getEntity()
    }, [entityId])

    useEffect(()=>{
        if (entity)
            setRelatedEntitiesAndAssets(entity, filter)
    }, [filter, entity])

    const setRelatedEntitiesAndAssets = (entity: EntityDetailResponse, filterText: string | null = null)=>{
        const relEntities = []
        for (const relatedEntity in entity.relatedEntities){
            const rel = entity.relatedEntities[relatedEntity]
            if (relatedEntitiesRelationFilter && (
                (relatedEntitiesRelationFilter == RelatedAssetsListEntitiesRelationFilter.AnyToMany && rel.relationship.endsWith(":1")) ||
                (relatedEntitiesRelationFilter == RelatedAssetsListEntitiesRelationFilter.AnyToOne && rel.relationship.endsWith(":n"))
            ))
                continue
            
            if (!filterText || relatedEntity.toLowerCase().includes(filterText))
                relEntities.push({entity: relatedEntity, relation:entity.relatedEntities[relatedEntity].relationship})
        }
        setRelatedEntities(relEntities)
        setRelatedAssets(Object.keys(entity.relatedAssets).filter(relAsset => !filterText || relAsset.toLowerCase().includes(filterText)))
    }

    const getEntity = async () => {
        setIsLoading(true)
        const data = await getEntityApi(entityId || "", isEditMode ? selectedGitBranch : null);
        setRelatedEntitiesAndAssets(data)
        setEntity(data)
        setIsLoading(false)
    };

    const getRelatedEntityText = (relatedEntity: string, relation:string) => {
        let currentEntityAmount = "One"
        let currentEntityName = entityId
        let relatedEntityAmount = "one"
        let relatedEntityName = relatedEntity
        if (relation.startsWith("n")){
            currentEntityAmount = "Many"
            currentEntityName = pluralize(entityId, 2)
        }

        if (relation.endsWith("n")){
            relatedEntityAmount = "many"
            relatedEntityName = pluralize(relatedEntity, 2)
        }
        return `${currentEntityAmount} ${currentEntityName} has ${relatedEntityAmount} ${relatedEntityName}`
    }

    return (
        <Box
            sx={{ width: "100%", height: "100%", overflowY: "scroll" }}
        >
            {isLoading ? (
                <Box sx={{textAlign: "center"}}><CircularProgress
                    sx={{
                        color: theme.palette.customColor.grey,
                        margin: "auto", 
                    }}
                    size={50}
                /></Box>
            ) : (
                <>
                    {relatedEntities.length > 0 && (
                        <Box sx={{ marginBottom: "24px" }}>
                            <Typography
                                variant="h5"
                                sx={{
                                    color: theme.palette.customColor.grey,
                                    marginBottom: "10px",
                                }}
                            >
                                Related entities
                            </Typography>
                            {relatedEntities.map((relatedEntity, index) => (
                                <Box
                                    className="modal-data flex-box-align-center"
                                    key={index}
                                    onClick={() => onSelectAsset(relatedEntity.entity)}
                                    sx={{ cursor: "pointer" }}
                                >
                                    <DataVirtual />
                                    <Box className="flex-box-center-space-between" sx={{ width: "100%" }}>
                                        <Typography variant="subtitle2" sx={{ color: theme.palette.customColor.darkGrey, marginRight: "16px" }}>
                                            {relatedEntity.entity}
                                        </Typography>

                                        <Typography variant="subtitle2" sx={{ color: theme.palette.customColor.darkGrey, marginRight: "16px" }}>
                                            {getRelatedEntityText(relatedEntity.entity, relatedEntity.relation)}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    )}

                    {relatedAssets.length > 0 && (
                        <Box>
                            <Typography
                                variant="h5"
                                sx={{
                                    color: theme.palette.customColor.grey,
                                    marginBottom: "10px",
                                }}
                            >
                                Related data assets
                            </Typography>
                            {relatedAssets.map((asset, index) => (
                                <Box
                                    className="modal-data flex-box-align-center"
                                    key={index}
                                    onClick={() => onSelectAsset(asset)}
                                    sx={{ cursor: "pointer" }}
                                >
                                    <span className="material-symbols-outlined modal-icon-data">
                                        database
                                    </span>
                                    {getDataAssetTitle(asset)}
                                </Box>
                            ))}
                        </Box>
                    )}

                    {relatedEntities.length === 0 && relatedAssets.length === 0 && (
                        <Typography variant="subtitle2">
                            No data assets found
                        </Typography>
                    )}
                </>
            )}
        </Box>
    );
};

export default RelatedAssetsList;