import React, { createContext, useReducer, useContext, ReactNode } from "react";
import {FieldType} from "../components/add-features-component/feature-types";
import {FieldAction} from "../actions/field-features-reducer";
import {fieldReducer} from "../reducers/features-reducer";

interface FieldFeaturesProviderProps {
    children: ReactNode;
}

interface FieldContextType {
    fieldFeatures: FieldType[];
    dispatchFieldAction: React.Dispatch<FieldAction>;
}

const FieldContext = createContext<FieldContextType | undefined>(undefined);

const FieldFeaturesProvider: React.FC<FieldFeaturesProviderProps> = ({ children }) => {
    const [fieldFeatures, dispatchFieldAction] = useReducer(fieldReducer, []);

    return (
        <FieldContext.Provider value={{fieldFeatures, dispatchFieldAction}}>
            {children}
        </FieldContext.Provider>
    );
};

const useField = (): FieldContextType => {
    const context = useContext(FieldContext);
    if (!context) {
        throw new Error("useField must be used within a FieldFeaturesProvider");
    }
    return context;
};

export { FieldFeaturesProvider, useField };
