// Generic types used for Features
export enum FeatureType {
    metric = "metric",
    firstLast = "first_last",
    formula = "formula",
    field = "field",
}

// Metric feature
export interface MeasureType {
    alias?: string,
    name: string,
    definition: string,
    description?: string,
    selected?: boolean
    isNew?: boolean
}

export interface FieldFeatureType {
    alias?: string,
    name: string
}

// First Last feature
export interface FirstLastFeature {
    name: string
    type: FeatureType,
    options: {
        method: "first" | "last",
        sortBy: string,
        field: string,
    }
}

// Metric feature
export interface MetricFeature {
    name: string
    type: FeatureType,
    measure: string
}

export interface AssetFeature {
    asset: string,
    features: (MetricFeature | FirstLastFeature)[],
    filters?: FeatureFilterApi[],
}

export interface FeatureFilterApi {
    field: string;
    operator: string;
    values: (string | number)[];
    type: string;
}

export interface DerivedFeature {
    name: string,
    sql: string,
    features: {
        feature: string,
        alias: string
    }[]   
}
