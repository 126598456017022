import {Box, Typography} from "@mui/material";
import {DataGrid, GridColDef, GridToolbar} from "@mui/x-data-grid";
import "./add-features.component.scss";
import {deleteAllFirstLastFeatures, deleteFirstLastFeature} from "actions/first-last-reducer";
import {deleteAllFormulaFeatures, deleteFormulaFeature} from "actions/formula-features-reducer";
import {deleteAllMetricFeatures, deleteMetricFeature} from "actions/metric-features-reducer";
import {ArrowRightSymbols} from "assets/icons";
import ButtonComponent from "components/shared/button/button.component";
import {useFirstLast} from "context/first-last-feature-context";
import {useFormula} from "context/formula-feature-context";
import {useGlobalContext} from "context/global-context";
import {useMetric} from "context/metric-feature-context";
import React, {useEffect, useMemo} from "react";
import {useParams} from "react-router-dom";
import {saveFeatures} from "services/entities";
import theme from "theme";
import {FeatureType} from "types/features";
import AssetTitle from "../shared/assets/asset-title/asset-title.component";
import AddFeaturesCard from "./add-features-card/add-features-card";
import {AssetFeatureData, DerivedFeatureData, FirstLastType} from "./feature-types";
import FeatureTypeDisplay from "./feature-type-display";
import {deleteAllFieldFeatures, deleteFieldFeature} from "../../actions/field-features-reducer";
import {useField} from "../../context/field-feature-context";


interface AddFeaturesComponentProps {
    handleClickFeatureClose: (shouldRefresh?: boolean) => void;
}

type FeaturesTableRow = Map<string, AssetFeatureData | DerivedFeatureData>

const AddFeaturesComponent: React.FC<AddFeaturesComponentProps> = ({handleClickFeatureClose}) => {
    const {entityId} = useParams<{ entityId: string }>();
    const {selectedGitBranch} = useGlobalContext();
    const {firstLastFeatures, dispatchFirstLastAction} = useFirstLast();
    const {fieldFeatures, dispatchFieldAction} = useField();
    const {metricFeatures, dispatchMetricAction} = useMetric();
    const {formulaFeatures, dispatchFormulaAction} = useFormula();
    const [rows, setRows] = React.useState<FeaturesTableRow>(new Map());

    const handleDelete = (id: AssetFeatureData["id"], type: FeatureType) => {
        // delete table row
        setRows((prevRows) => {
            const newRows: FeaturesTableRow = new Map(prevRows);
            newRows.delete(id);
            return newRows;
        });
        // delete feature from the state
        if (type === FeatureType.field) dispatchFieldAction(deleteFieldFeature(id));
        if (type === FeatureType.metric) dispatchMetricAction(deleteMetricFeature(id));
        if (type === FeatureType.firstLast) dispatchFirstLastAction(deleteFirstLastFeature(id));
        if (type === FeatureType.formula) dispatchFormulaAction(deleteFormulaFeature(id));
    };


    const columns: GridColDef[] = [
        {
            field: "type",
            headerName: "Type",
            sortable: false,
            renderCell: (params) => {
                if (params.value === FeatureType.firstLast) {
                    const featureDetail = params.row.feature as FirstLastType;
                    return <FeatureTypeDisplay featureType={featureDetail.method} />;
                }
                return <FeatureTypeDisplay featureType={params.value} />;
            },
        },
        {field: "featureName", headerName: "Name", flex: 1, sortable: true},
        {
            field: "dataAssetId",
            headerName: "Origin data asset",
            flex: 1,
            sortable: false,
            renderCell: ({row}) => (
                <AssetTitle
                    assetId={row.dataAssetId}
                />
            ),
        },
        {
            field: "deleteAction",
            headerName: "",
            width: 30,
            sortable: false,
            renderCell: (params) => (
                <span
                    className="material-symbols-outlined"
                    style={{cursor: "pointer"}}
                    onClick={() => handleDelete(params.row.id, params.row.type)}
                >
                    delete
                </span>
            ),
        },
    ];

    const onExit = (shouldRefresh?: boolean) => {
        dispatchFirstLastAction(deleteAllFirstLastFeatures());
        dispatchFieldAction(deleteAllFieldFeatures());
        dispatchMetricAction(deleteAllMetricFeatures());
        dispatchFormulaAction(deleteAllFormulaFeatures());
        handleClickFeatureClose(shouldRefresh);
    };

    useEffect(() => {
        if (Object.values(firstLastFeatures).length > 0) {
            setRows((prevRows) => {
                const newRows: FeaturesTableRow = new Map(prevRows);

                for (const feature of firstLastFeatures) {
                    (feature.fields || []).forEach(field => {
                        const featureName = `${field.alias || field.name}`;
                        const rowId = `${feature.id}-${featureName}`;
                        const row = {
                            id: rowId,
                            featureName: featureName,
                            dataAssetId: feature.dataAssetId,
                            dataAssetType: feature.dataAssetType,
                            type: FeatureType.firstLast,
                            feature: {...feature, fields: [field]},
                        };
                        newRows.set(rowId, row);
                    });
                }

                return newRows;
            });
        }
    }, [firstLastFeatures]);


    useEffect(() => {
        if (Object.values(metricFeatures).length > 0) {
            setRows((prevRows) => {
                const newRows: FeaturesTableRow = new Map(prevRows);

                for (const feature of metricFeatures) {
                    (feature.measures || []).forEach(measure => {
                        const rowId = measure.name;
                        const row: AssetFeatureData = {
                            id: measure.name,
                            featureName: measure.alias || measure.name,
                            dataAssetId: feature.dataAssetId,
                            dataAssetType: feature.dataAssetType,
                            type: FeatureType.metric,
                            feature: {...feature, measures: [measure]}
                        };
                        newRows.set(rowId, row);
                    });
                }
                return newRows;
            });
        }
    }, [metricFeatures]);

    useEffect(() => {
        if (Object.values(fieldFeatures).length > 0) {
            setRows((prevRows) => {
                const newRows: FeaturesTableRow = new Map(prevRows);

                for (const feature of fieldFeatures) {
                    (feature.fields || []).forEach(field => {
                        const rowId = field.name;
                        const row: AssetFeatureData = {
                            id: field.name,
                            featureName: field.alias || field.name,
                            dataAssetId: feature.dataAssetId,
                            dataAssetType: feature.dataAssetType,
                            type: FeatureType.field,
                            feature: {...feature, fields: [field]}
                        };
                        newRows.set(rowId, row);
                    });
                }
                return newRows;
            });
        }
    }, [fieldFeatures]);

    useEffect(() => {
        if (Object.values(formulaFeatures).length > 0) {
            setRows((prevRows) => {
                const newRows: FeaturesTableRow = new Map(prevRows);

                for (const feature of formulaFeatures) {
                    const newFeatureRow: DerivedFeatureData = {
                        id: feature.name,
                        featureName: feature.name,
                        type: FeatureType.formula,
                        feature: feature
                    };
                    newRows.set(feature.name, newFeatureRow);
                }

                return newRows;
            });
        }
    }, [formulaFeatures]);

    const handleNext = async () => {
        await saveFeatures(
            (entityId as string),
            (selectedGitBranch as string),
            firstLastFeatures,
            metricFeatures,
            fieldFeatures,
            formulaFeatures
        );
        onExit(true);
    };

    const rowValues = useMemo(() => Array.from(rows.values()), [rows]);

    return (
        <Box className="flex-box-col-center"
             sx={{width: "90%", margin: "0 auto", overflow: "hidden"}}>
            <Box className="flex-box-align-center flex-box-end" sx={{width: "100%", marginTop: "24px"}}>
                <ButtonComponent
                    variant="outlined"
                    label="Exit"
                    onClick={() => onExit()}
                    sx={{marginRight: "16px"}}
                />

                <ButtonComponent
                    variant="contained"
                    endIcon={<ArrowRightSymbols/>}
                    label="Add features"
                    onClick={handleNext}
                    disabled={!Boolean(rows?.size)}
                />
            </Box>
            <Typography
                variant="h3"
                sx={{
                    fontSize: "42px",
                    color: theme.palette.customColor.dark,
                    lineHeight: "46px",
                    letterSpacing: "-0.84px",
                    margin: "50px 0 24px 0",
                }}
            >
                Add features
            </Typography>
            <Typography variant="subtitle2" sx={{fontWeight: 400, color: theme.palette.customColor.grey}}>
                Add features to&nbsp;
                <Typography component="span"
                            sx={{fontSize: "16px", fontWeight: 500, color: theme.palette.customColor.dark}}>
                    {entityId}
                </Typography>
            </Typography>

            <Box>
                <AddFeaturesCard featuresAdded={rows.size > 0}/>
            </Box>

            {rows.size > 0 && (
                <Box className="feature-table" sx={{maxWidth: "1232px", width: "100%"}}>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: theme.palette.customColor.black,
                            fontWeight: 600,
                            marginBottom: "16px",
                        }}
                    >
                        Added features
                    </Typography>
                    <DataGrid
                        hideFooter={true}
                        rows={rowValues}
                        columns={columns}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        className="entity-data-grid feature-data-grid"
                        slots={{toolbar: GridToolbar}}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: false,
                            },
                        }}
                        autoHeight
                    />
                </Box>
            )}
        </Box>
    );
};

export default AddFeaturesComponent;
