import {styled, Theme} from "@mui/material/styles";
import Switch, {SwitchProps} from "@mui/material/Switch";
import React from "react";

const CustomSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({theme}: { theme: Theme }) => ({
    height: "auto",
    width: "auto",
    padding: "0",
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: "5px 0 0 8px",
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(11px)",
            color: theme.palette.customColor.white,
            "& + .MuiSwitch-track": {
                border: `2px solid ${theme.palette.customColor.purple}`,
                backgroundColor: theme.palette.mode === "dark" ? theme.palette.customColor.lightGrey3 : theme.palette.customColor.purple,
                opacity: 1,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
            "& .MuiSwitch-thumb": {
                width: "20px",
                height: "20px",
                backgroundColor: theme.palette.customColor.white,
                margin: "-2px 0 0 3px",
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: theme.palette.customColor.brightGreen,
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color: theme.palette.customColor.purple,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
            cursor: "not-allowed"
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 16,
        height: 16,
        backgroundColor: theme.palette.customColor.slateBlue,
    },
    "& .MuiSwitch-track": {
        width: 46,
        height: 26,
        borderRadius: "100px",
        border: "2px solid #DBDADD",
        boxSizing: "border-box",
        backgroundColor: theme.palette.mode === "light" ? theme.palette.customColor.lightestGrey3 : theme.palette.customColor.lightestGrey4,
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));

export default CustomSwitch;