import React, {useState} from 'react';
import {Box, Divider, TextField, Typography} from "@mui/material";
import theme from "theme";
import ContextsTable from "./contexts-table/contexts-table";
import ButtonComponent from "../../shared/button/button.component";
import CopyButton from "../../shared/button/copy-button.component";
import copy from "copy-to-clipboard";
import { useLoggedInUser } from 'context/logged-in-user-context';

enum ButtonState {
    idle = "idle",
    clicked = "clicked",
}

const buttonStates = {
    [ButtonState.idle]: {
        text: "Copy",
        padding: "5px",
    },
    [ButtonState.clicked]: {
        text: "Copied!",
        padding: "5px 10px",
    },
};

const AdminAccountComponent: React.FC = ({}) => {
    const [buttonState, setButtonState] = useState<ButtonState>(ButtonState.idle);
    const {user} = useLoggedInUser();
    
    const copyToClipboard = () => {
        copy(user?.tenantId || "", {message: "Copied!"});
        setButtonState(ButtonState.clicked);
        setTimeout(() => setButtonState(ButtonState.idle), 3000);
    };

    return (
        <Box>
            <Typography
                variant="h2"
                sx={{
                    color: theme.palette.customColor.dark,
                    fontWeight: 600,
                    marginBottom: "32px",
                }}
            >
                Account {user?.organization}
            </Typography>

            <Divider sx={{height: "1px", backgroundColor: theme.palette.customColor.lightGrey1, marginBottom: "40px"}}/>

            <Box className="flex-box-col-start">
                <Typography
                    variant="body2"
                    sx={{
                        color: theme.palette.customColor.black,
                        marginBottom: "32px"
                    }}
                >
                    Details
                </Typography>

                <Box sx={{width: "100%"}}>
                    <Box sx={{marginBottom: "32px"}}>
                        <Typography
                            variant="subtitle2"
                            sx={{
                                color: theme.palette.customColor.black,
                                marginBottom: "16px"
                            }}
                        >
                            Account name
                        </Typography>

                        <TextField
                            variant="filled"
                            value={user?.organization}
                            type="text"
                            disabled
                            sx={{width: "100%"}}
                        />
                    </Box>

                    <Box>
                        <Typography
                            variant="subtitle2"
                            sx={{
                                color: theme.palette.customColor.black,
                                marginBottom: "16px"
                            }}
                        >
                            Account ID
                        </Typography>

                        <Box sx={{position: "relative"}}>
                            <TextField
                                variant="filled"
                                value={user?.tenantId}
                                type="text"
                                disabled
                                sx={{width: "100%"}}
                            />
                            <CopyButton
                                onClick={copyToClipboard}
                                buttonState={buttonStates[buttonState]}
                                sx={{
                                    backgroundColor: theme.palette.customColor.white,
                                    top: "4px",
                                    right: "5px",
                                    borderRadius: "12px",
                                    padding: "14px 16px",
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Divider sx={{height: "1px", backgroundColor: theme.palette.customColor.lightGrey1, margin: "40px 0"}}/>

            <Box>
                <Typography
                    variant="h4"
                    sx={{
                        color: theme.palette.customColor.dark,
                        fontWeight: 600,
                        marginBottom: "24px"
                    }}
                >
                    Project
                </Typography>

                <Typography
                    variant="subtitle2"
                    sx={{
                        color: theme.palette.customColor.black,
                        marginBottom: "8px"
                    }}
                >
                    Sync graph
                </Typography>

                <Typography
                    variant="subtitle2"
                    sx={{
                        color: theme.palette.customColor.slateGrey,
                        fontWeight: 400,
                        marginBottom: "16px"
                    }}
                >
                    Lynk graph enables LLMs access your project’s metadata to increase AI accuracy and efficiency.
                    After committing changes to Git main branch, it is recommended to update the Graph accordingly to
                    keep it synced with the latest changes.
                    Note that the process of syncing might take a few minutes.
                </Typography>

                <ButtonComponent
                    onClick={() => {
                    }}
                    variant="containedGrey"
                    label="Sync graph now"
                    startIcon={
                        <span className="material-symbols-outlined" style={{fontSize: "20px"}}>replay</span>
                    }
                    sx={{
                        boxSizing: "border-box",
                        backgroundColor: theme.palette.customColor.lightestGrey5,
                        fontSize: "14px",
                        lineHeight: "22px",
                        padding: "10px 12px"
                    }}
                />
            </Box>

            <Divider sx={{height: "1px", backgroundColor: theme.palette.customColor.lightGrey1, margin: "32px 0"}}/>

            <Box>
                <Typography
                    variant="body2"
                    sx={{
                        color: theme.palette.customColor.black,
                        marginBottom: "8px"
                    }}
                >
                    Contexts
                </Typography>

                <Typography
                    variant="subtitle2"
                    sx={{
                        color: theme.palette.customColor.slateGrey,
                        fontWeight: 400,
                        marginBottom: "16px"
                    }}
                >
                    Add contexts to enable different teams create different Entity features definitions
                </Typography>

                <ContextsTable/>
            </Box>
        </Box>
    );
}

export default AdminAccountComponent;