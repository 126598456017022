import React, {useEffect, useState} from "react";
import {Box, Container, Divider, Typography} from "@mui/material";
import theme from "theme";
import BusinessEntityTabs from "./business-entity-tabs/business-entity-tabs.component";
import BusinessEntitiesListBreadcrumbs
    from "./business-entity-breadcrumbs/business-entity-breadcrumbs.component";
import {getEntity as getEntityApi} from "../../services/entities";
import {EntityDetailResponse} from "types";
import {useOutletContext, useParams} from "react-router-dom";
import {useGlobalContext} from "../../context/global-context";
import {InfoIcon} from "lucide-react";
import {handleRequestError} from "../../utils/axios";
import LoadingSpinner from "../shared/LoadingSpinner";
import {DataAssetColumn, getDataAssetById} from "services/data-assets";
import ButtonComponent from "../shared/button/button.component";
import {EditSymbols} from "../../assets/icons";
import BusinessEntityFeaturesModal from "./business-entity-features-modal/business-entities-features-modal.component";
import AssetTitle from "../shared/assets/asset-title/asset-title.component";
import AliasesComponent from "../shared/aliases/aliases.component";
import ConfirmationModal from "../shared/modal/confirmation-modal";
import BusinessEntitiesListDescription from "./business-entity-description/business-entity-description.component";
import BusinessEntitiesModalViewComponent from "components/business-entities-component/business-entities-modal/business-entities-modal-view.component";

interface BusinessEntityComponentProps {
    onClickEdit: () => void;
    onDeleteClick: (entityId: string) => void;
}

const BusinessEntityComponent: React.FC = () => {
    const {entityId} = useParams<{ entityId: string }>();
    const {selectedGitBranch, isEditMode, selectedContext} = useGlobalContext();
    const [entity, setEntity] = useState<EntityDetailResponse | null>(null);
    const [keyTableFields, setKeyTableFields] = useState<DataAssetColumn[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [isFeaturesModalOpen, setIsFeaturesModalOpen] = useState<boolean>(false);
    const {onDeleteClick} = useOutletContext<BusinessEntityComponentProps>();
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

    const handleOpenConfirmationModal = () => {
        setIsConfirmationModalOpen(true);
    };

    const handleCloseConfirmationModal = () => {
        setIsConfirmationModalOpen(false);
    };

    const handleConfirm = () => {
        onDeleteClick(entityId as string);
        setIsConfirmationModalOpen(false);
    };

    const onEditModalClosed = (shouldRefresh: boolean)=>{
        if (shouldRefresh){
            getEntity()
        }
        setShowEditModal(false)
    }

    const getEntity = async () => {
        setIsLoading(true);
        setErrorMessage(null);

        try {
            const data = await getEntityApi(entityId || "", isEditMode ? selectedGitBranch : null);
            if (data.keyTable) {
                const keyAsset = await getDataAssetById(data.keyTable, isEditMode ? selectedGitBranch : null)
                setKeyTableFields(keyAsset.columns);
            }
            setEntity(data);
        } catch (error: any) {
            const message = handleRequestError(error);
            if (message) setErrorMessage(message);
            setEntity(null);
        } finally {
            setIsLoading(false);
        }
    };

    const handleToggleAddFeatureModalOpen = async (isOpen: boolean, shouldRefresh?: boolean) => {
        setIsFeaturesModalOpen(isOpen);
        if (shouldRefresh)
            await getEntity()
    };

    useEffect(() => {
        getEntity();
    }, [entityId, isEditMode, selectedGitBranch, selectedContext]);

    useEffect(() => {
        if (entity) document.getElementsByTagName("title")[0].text = `${entity.name} - Lynk Studio`
    }, [entity]);


    return (
        <Container>
            <Box
                className="flex-box-start"
                flexDirection="column"
                sx={{padding: "32px 0 0 50px"}}
            >

                <BusinessEntitiesListBreadcrumbs entityName={entity?.name || entityId || ""}/>

                {errorMessage && !entity && (
                    <Box className="flex-box-row" margin="20px 0">
                        <InfoIcon/>
                        <Typography variant="subtitle2" paddingLeft="10px">
                            We couldn't load entity due to an error:&nbsp;
                            {errorMessage}
                        </Typography>
                    </Box>
                )}

                {Boolean(entity) && (
                    <Box sx={{width: "100%"}}>
                        <Box margin="24px 0">
                            <Box className="flex-box-center-space-between">
                                <Typography variant="h2"
                                            sx={{
                                                fontSize: "42px",
                                                color: theme.palette.customColor.dark,
                                                letterSpacing: "-0.84px",
                                                lineHeight: "46px",
                                                fontWeight: 600,
                                                textTransform: "capitalize"
                                            }}>
                                    {entity?.name}
                                </Typography>

                                <Box className="flex-box-center-space-between">
                                    {isEditMode ? (
                                        <>
                                            <ButtonComponent onClick={()=>setShowEditModal(true)} variant="contained" label="Edit"
                                                             startIcon={<EditSymbols/>}/>

                                            <ButtonComponent onClick={()=>handleToggleAddFeatureModalOpen(true)} variant="containedGrey"
                                                             label="+ Add features" sx={{
                                                boxSizing: "border-box",
                                                backgroundColor: theme.palette.customColor.lightestGrey5,
                                                margin: "0 16px",
                                                padding: "12px 16px"
                                            }}/>

                                            <span className="material-symbols-outlined"
                                                  style={{cursor: "pointer", fontSize: "18px"}}
                                                  onClick={handleOpenConfirmationModal}>delete</span>
                                        </>
                                    ) : null}

                                </Box>
                            </Box>

                            <ConfirmationModal
                                open={isConfirmationModalOpen}
                                onClose={handleCloseConfirmationModal}
                                onConfirm={handleConfirm}
                                title={`Are you sure you want to delete ${entity?.name}?`}
                                body={`This action will permanently delete the ${entity?.name}, which may include essential configurations, data sets, or foundational elements required for system functionality.`}
                                confirmText="Yes, delete"
                                cancelText="Don’t delete"
                            />

                            {entity && (
                                <BusinessEntitiesListDescription description={entity.description}/>
                            )}

                            <Box className="flex-box-start" sx={{marginTop: "24px"}}>
                                {entity?.keyTable && (
                                    <Box>
                                        <Typography variant="h5"
                                                    sx={{
                                                        color: theme.palette.customColor.slateGrey,
                                                        marginBottom: "12px"
                                                    }}>
                                            Key data asset
                                        </Typography>

                                        {entity?.keyTable && <AssetTitle assetId={entity.keyTable}/>}
                                    </Box>
                                )}

                                {entity?.aliases?.length ? (
                                    <>
                                        <Divider orientation="vertical" sx={{
                                            backgroundColor: theme.palette.customColor.lightGrey1,
                                            width: "1px",
                                            height: "58px",
                                            border: 0,
                                            margin: "0 40px"
                                        }}/>

                                        <AliasesComponent variant="light" title="Also known as"
                                                          labels={entity?.aliases}/>
                                    </>
                                ) : null}
                            </Box>

                        </Box>
                        <BusinessEntityTabs entity={entity} keyTableFields={keyTableFields} onDataRefresh={getEntity}/>
                    </Box>
                )}

                {(!entity && !isLoading && !errorMessage) && (
                    <Box className="flex-box-row" margin="20px 0">
                        <InfoIcon/>
                        <Typography variant="subtitle2" paddingLeft="10px">No entity information found</Typography>
                    </Box>
                )}
            </Box>

            {entity && (<BusinessEntityFeaturesModal entity={entity} isModalOpen={isFeaturesModalOpen}
                                                     onClose={(shouldRefresh) => handleToggleAddFeatureModalOpen(false, shouldRefresh)}/>)}

            <BusinessEntitiesModalViewComponent
                entityId={entityId}
                open={showEditModal}
                onClose={onEditModalClosed}
            />

            <LoadingSpinner open={isLoading}/>
        </Container>
    );
};

export default BusinessEntityComponent;