import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import DataAssetTable from "./data-asset-table-columns/data-asset-table-columns.component";
import theme from "theme";
import { DataAssetFullResponse } from "../../../services/data-assets";
import DataAssetTableMeasures from "./data-asset-table-measures/data-asset-table-measures.component";
import {CustomTabPanel, getTabProps} from "../../shared/tabs/tabs-component";

interface DataAssetTabsProps {
    asset: DataAssetFullResponse;
}

const DataAssetTabs: React.FC<DataAssetTabsProps> = ({ asset }) => {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="Data asset tabs"
                    sx={{
                        "& .MuiTab-root": {
                            minWidth: "auto",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "24px",
                            marginRight: "32px",
                            padding: "16px 0",
                        },
                        "& .MuiTabs-indicator": {
                            backgroundColor: theme.palette.customColor.purple,
                        },
                        "& .Mui-selected": {
                            color: `${theme.palette.customColor.purple} !important`,
                        },
                    }}
                >
                    <Tab label="Fields" {...getTabProps(0)} />
                    <Tab label="Measures" {...getTabProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <DataAssetTable columns={asset.columns} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <DataAssetTableMeasures measures={asset.measures} />
            </CustomTabPanel>
        </Box>
    );
};

export default DataAssetTabs;