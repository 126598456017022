import React, { FC } from "react";
import { Box, Chip, MenuItem, Select, Typography } from "@mui/material";
import theme from "theme";

interface SelectChipsProps {
    options: string[];
    selectedValues: string[];
    onChange: (newValues: string[]) => void;
    onOpen: () => void;
    onClose: () => void;
    placeholder?: string;
    maxChips?: number;
    open: boolean;
    multiple?: boolean;
}

const SelectChips: FC<SelectChipsProps> = ({
        options,
        selectedValues,
        onChange,
        onOpen,
        onClose,
        placeholder = "Select",
        maxChips = 4,
        open,
        multiple = false
}) => {
    return (
        <Select
            multiple={multiple}
            value={selectedValues}
            onChange={(e) => {onChange(e.target.value instanceof Array ? e.target.value : [e.target.value])}}
            onOpen={onOpen}
            onClose={onClose}
            renderValue={(selected) => {
                const displayedChips = selected.slice(0, maxChips);
                return (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "6px" }}>
                        {displayedChips.map((value) => (
                            <Chip
                                key={value}
                                label={value}
                                className="asset-title-chip data-asset-name-db"
                                color="primary"
                                variant="outlined"
                            />
                        ))}
                    </Box>
                );
            }}
            sx={{ width: "100%", position: "relative" }}
            displayEmpty
            variant="outlined"
            IconComponent={() => (
                <span
                    className="material-symbols-outlined"
                    style={{ position: "absolute", right: "10px" }}
                >
          {open ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        </span>
            )}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
                PaperProps: {
                    sx: {
                        overflowY: "scroll",
                        borderRadius: "16px",
                        border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                        background: theme.palette.customColor.white,
                        boxShadow: "0 4px 13px 0 rgba(97, 97, 97, 0.02)",
                        minWidth: "auto !important",
                        width: "308px",
                        maxHeight: "373px",
                        marginTop: "10px",
                        "& ul": {
                            display: "flex",
                            flexDirection: "column",
                            width: "auto !important",
                            gap: "3px",
                            padding: "6px !important",
                        },
                        "& .MuiMenuItem-root": {
                            minHeight: "44px",
                            borderRadius: "10px",
                            padding: "10px 16px",
                            "&:hover": {
                                backgroundColor: theme.palette.customColor.lightestGrey5,
                            },
                            "&.Mui-selected": {
                                backgroundColor: theme.palette.customColor.lightestGrey5,
                                "&:hover": {
                                    backgroundColor: theme.palette.customColor.lightestGrey5,
                                },
                            },
                        },
                    },
                },
            }}
        >
            {options.map((option) => (
                <MenuItem
                    key={option}
                    value={option}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: theme.palette.customColor.darkGrey,
                        }}
                    >
                        {option}
                    </Typography>

                    {selectedValues.includes(option) && (
                        <span
                            className="material-symbols-outlined"
                            style={{ color: theme.palette.primary.main, fontSize: "20px" }}
                        >
              check
            </span>
                    )}
                </MenuItem>
            ))}
        </Select>
    );
};

export default SelectChips;