import React, { useState } from "react";
import { Box } from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coldarkCold } from "react-syntax-highlighter/dist/esm/styles/prism";
import copy from "copy-to-clipboard";
import CopyButton from "../button/copy-button.component";
import theme from "../../../theme";

interface Props {
    sql: string;
}

const adjustedThem = coldarkCold;
adjustedThem['pre[class*="language-"]'] = {
    ...adjustedThem['pre[class*="language-"]'],
    margin: 0,
    position: "absolute",
    top: 0,
    left: "10px",
    right: "10px",
    bottom: 0,
    borderRadius: "20px",
};

enum ButtonState {
    idle = "idle",
    clicked = "clicked",
}

const buttonStates = {
    [ButtonState.idle]: {
        text: "Copy",
        padding: "5px",
    },
    [ButtonState.clicked]: {
        text: "Copied!",
        padding: "5px 10px",
    },
};

const SqlView: React.FC<Props> = ({ sql }) => {
    const [buttonState, setButtonState] = useState<ButtonState>(ButtonState.idle);

    const copyToClipboard = () => {
        copy(sql, {
            message: "Copied!",
        });
        setButtonState(ButtonState.clicked);
        setTimeout(() => setButtonState(ButtonState.idle), 3000);
    };

    return (
        <Box sx={{ height: "100%", padding: "0 10px", position: "relative" }}>
            <SyntaxHighlighter language="javascript" style={adjustedThem}>
                {sql}
            </SyntaxHighlighter>
            <CopyButton
                onClick={copyToClipboard}
                buttonState={buttonStates[buttonState]}
                sx={{
                    color: theme.palette.customColor.white,
                    backgroundColor: theme.palette.customColor.black,
                    borderRadius: "10px",
                    top: "16px",
                    right: "24px",
                    padding: "7px 10px",
                    "&:hover": {
                        backgroundColor: theme.palette.customColor.black,
                    },
                }}
            />
        </Box>
    );
};

export default SqlView;