import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {getSystemContexts} from "services/system";
import SelectComponent from "../../shared/select/select.component";
import { SelectChangeEvent, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import theme from "theme";
import {useGlobalContext} from "../../../context/global-context";
import {ChevronDown} from "lucide-react";

interface ContextOption {
    title: string;
    value: string;
}

const HeaderContext: React.FC = () => {
    const { selectedContext, setSelectedContext, contexts, setContexts } = useGlobalContext();
    const [open, setOpen] = React.useState<boolean>(false);
    const [contextData, setContextData] = useState<ContextOption[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getSystemContexts();
            setContexts(data);
        };
        fetchData();
    }, []);

    useEffect(() => {
        setContextData(contexts.map(context => ({title: context.name, value: context.name})));
    }, [contexts]);

    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        setSelectedContext(event.target.value);
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Box
            className="flex-box-align-center"
            sx={{
                boxSizing: "border-box",
                width: "132px",
                borderRadius: "10px",
                transition: "border 0.3s ease",
                "& .MuiSelect-select[aria-expanded='true']": {
                    border: `3px solid ${theme.palette.customColor.lightGrey1}`,
                    padding: "14px",
                },
                "& .MuiSelect-select[aria-expanded='false']": {
                    border: `1px solid ${theme.palette.customColor.lightGrey1}`
                },
            }}
        >
            <SelectComponent
                options={contextData}
                value={selectedContext}
                onChange={handleSelectChange}
                placeholder="Select a Product"
                onOpen={handleOpen}
                onClose={handleClose}
                renderOption={(option) => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{
                            borderRadius: "10px",
                            padding: "10px 16px",
                            "&:hover": {
                                backgroundColor: `${theme.palette.customColor.lightestGrey5} !important`,
                            },
                            "&.Mui-selected": {
                                backgroundColor: `${theme.palette.customColor.lightestGrey5} !important`,
                            },
                        }}
                    >
                        <span className="material-symbols-outlined">tv</span>
                        <Typography
                            variant="h5"
                            sx={{
                                color: theme.palette.customColor.dark,
                                marginLeft: "8px",
                                textTransform: "capitalize"
                            }}
                        >
                            {option.title}
                        </Typography>
                        {option.value === selectedContext && (
                            <span
                                className="material-symbols-outlined"
                                style={{
                                    color: theme.palette.primary.main,
                                    fontSize: "20px",
                                    position: "absolute",
                                    right: "10px",
                                }}
                            >
                                check
                            </span>
                        )}
                    </MenuItem>
                )}
                showSearch={false}
                IconComponent={(props) => (
                    <ChevronDown
                        {...props}
                        style={{
                            color: theme.palette.customColor.dark,
                            width: "17px",
                            height: "17px",
                            strokeWidth: "2.2px",
                            top: "unset !important",
                        }}
                    />
                )}
                renderValue={(value) => {
                    const selectedOption = contextData.find((option) => option.value === value);
                    return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span
                                className="material-symbols-outlined"
                                style={{ marginRight: "8px" }}
                            >
                                tv
                            </span>
                            <Typography
                                variant="h5"
                                sx={{ color: theme.palette.customColor.dark, textTransform: "capitalize" }}
                            >
                                {selectedOption?.title}
                            </Typography>
                        </div>
                    );
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                    },
                    PaperProps: {
                        sx: {
                            width: "261px",
                            borderRadius: "16px",
                            border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                            backgroundColor: theme.palette.customColor.white,
                            boxShadow: "0px 4px 13px 0px rgba(97, 97, 97, 0.02)",
                            marginTop: "10px",
                            padding: "6px",
                            "& ul": {
                                display: "flex",
                                flexDirection: "column",
                                gap: "3px",
                                padding: 0,
                            },
                        },
                    },
                }}
            />
        </Box>
    );
};

export default HeaderContext;