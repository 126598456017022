import React from "react";
import {Box, Button, Typography, Modal} from "@mui/material";
import ButtonComponent from "components/shared/button/button.component";
import {ArrowLeftSymbols} from "assets/icons";
import {DataAssetFullResponse} from "services/data-assets";
import theme from "theme";
import {DataAssetName} from "../../add-features-component/data-asset-name";
import AddFeaturesMeasuresStepCreate from "../../add-features-component/add-features-modal-content/add-features-modal-data/add-features-data-content/add-features-measures/add-features-measures-modal/add-features-measures-step-create/add-features-measures-create";
import { MeasureType } from "types/features";

interface MeasureCreateModalProps {
    open: boolean;
    onClose: () => void;
    handleOpenMeasuresStep: () => void;
    selectedAsset: DataAssetFullResponse;
    handleNewMeasureCreated: (measure: MeasureType) => void;
}

const MeasuresCreateModal: React.FC<MeasureCreateModalProps> = ({
                                                                   open,
                                                                   onClose,
                                                                   handleOpenMeasuresStep,
                                                                   selectedAsset,
                                                                   handleNewMeasureCreated,
                                                               }) => {
    return (
        <Modal
            key="create-measure-modal"
            open={open}
            onClose={onClose}
        >
            <Box className="container-modal-small">
                <Button className="modal-close" onClick={onClose}>
                    <span className="material-symbols-outlined modal-close-icon">
                        close
                    </span>
                </Button>

                <Box>
                    <Box className="flex-box-align-center">
                        <ButtonComponent
                            onClick={handleOpenMeasuresStep}
                            variant="text"
                            label={<ArrowLeftSymbols />}
                            sx={{
                                minWidth: "auto",
                                fontSize: "14px",
                                lineHeight: "22px",
                                margin: 0,
                                padding: 0,
                            }}
                        />
                        <Typography
                            variant="h4"
                            sx={{width: "100%", textAlign: "center", fontWeight: 600}}
                        >
                            Create Measure
                        </Typography>
                    </Box>

                    <Box sx={{margin: "24px 0"}}>
                        <Typography variant="h5" sx={{color: theme.palette.customColor.darkGrey}}>
                            Selected Data Asset
                        </Typography>
                        <DataAssetName assetId={selectedAsset.id} />
                    </Box>
                </Box>

                <AddFeaturesMeasuresStepCreate
                    onClose={onClose}
                    onNewMeasureCreated={handleNewMeasureCreated}
                />
            </Box>
        </Modal>
    );
};

export default MeasuresCreateModal;