import {ArrowLeftSymbols, ArrowRightSymbols} from "assets/icons";
import AssetDetails from "components/data-asset-component/data-asset-stepper/asset-details/asset-details";
import ButtonComponent from "components/shared/button/button.component";
import {useGlobalContext} from "context/global-context";
import React, {useEffect, useMemo, useState} from "react";
import {Box, Modal, Paper, Typography} from "@mui/material";
import {DataAssetFullResponse, updateDataAsset} from "services/data-assets";
import theme from "theme";

interface DataAssetModalProps {
    open: boolean;
    onClose: (shouldRefresh: boolean) => void;
    dataAsset: DataAssetFullResponse;
}

export type assetDataType = {
    [key: string]: string | string[];
}

const DataAssetModal: React.FC<DataAssetModalProps> = ({open, onClose, dataAsset}) => {
    const {selectedGitBranch} = useGlobalContext();
    const [updatedDataAsset, setDataAsset] = useState<DataAssetFullResponse>(dataAsset);
    const handleClose = () => onClose(false);
    const handleDataAssetChange = (updatedAsset: assetDataType) => {
        const [key, value] = Object.entries(updatedAsset)[0];

        setDataAsset((prevState) => {
            if (key === "timeField") {
                return {
                    ...prevState,
                    defaults: {
                        ...prevState.defaults,
                        timeField: value,
                    },
                } as DataAssetFullResponse;
            }
            return {
                ...prevState,
                [key]: value,
            } as DataAssetFullResponse;
        });
    };
    const saveDataAsset = async () => {
        await updateDataAsset({
            asset: updatedDataAsset.id,
            description: updatedDataAsset.description,
            businessKeys: updatedDataAsset.businessKeys,
            defaults: updatedDataAsset.defaults,
        }, selectedGitBranch as string);
        onClose(true);
    };
    const isSaveEnabled = useMemo(() => {
        return Boolean(updatedDataAsset.businessKeys.length) && Boolean(updatedDataAsset.defaults?.timeField?.length > 0)
    }, [updatedDataAsset])

    // If modal is closed, we need to reset its data
    useEffect(()=>{
        if (!open)
            setDataAsset(dataAsset)
    },[open])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="container-modal">
                <Box sx={{maxWidth: "1232px", width: "100%", margin: "32px auto 0 auto", paddingBottom: "100px"}}
                     className="flex-box-col">
                    <Box className="flex-box-space-between"
                         sx={{alignItems: "center", width: "100%", marginBottom: "20px"}}>
                        <Box sx={{width: "175px"}}>
                            <ButtonComponent
                                variant="text"
                                label="Exit"
                                onClick={handleClose}
                                startIcon={<ArrowLeftSymbols/>}
                            />
                        </Box>

                        <Box className="flex-box-col-center">
                            <Typography variant="h2" sx={{color: theme.palette.customColor.dark}}>
                                {dataAsset.name}
                            </Typography>
                        </Box>

                        <Box className="flex-box-align-center" sx={{width: "175px"}}>
                            <ButtonComponent
                                variant="contained"
                                endIcon={<ArrowRightSymbols/>}
                                label="Commit changes"
                                onClick={() => saveDataAsset()}
                                disabled={!isSaveEnabled}
                            />
                        </Box>
                    </Box>

                    <Box sx={{width: "100%"}}>
                        <Paper
                            className="flex-box-col"
                            square
                            elevation={0}
                            sx={{alignItems: "center", bgcolor: "background.default"}}
                        >
                            <AssetDetails assetId={updatedDataAsset.id}
                                          description={updatedDataAsset.description}
                                          businessKeys={[...updatedDataAsset.businessKeys]} // Create a new array to ensure React detects the change
                                          timeField={updatedDataAsset.defaults?.timeField}
                                          columns={[...updatedDataAsset.columns]}
                                          updateDataAsset={handleDataAssetChange}/>
                        </Paper>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default DataAssetModal;