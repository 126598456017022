import {useGlobalContext} from "context/global-context";
import React, {useEffect, useState} from "react";
import {Box, Chip} from "@mui/material";
import {
    DataGrid,
    GridRenderCellParams,
    useGridApiRef,
} from "@mui/x-data-grid";
import CustomTablePagination
    from "../../shared/table/table-pagination/table-pagination.component";
import {Link} from "react-router-dom";
import "./data-assets-table.component.scss";
import {DataAssetsPaginationResponse, getDataAssets, PaginationParams} from "services/data-assets";
import SearchComponent from "../../shared/search/search.component";
import {DataAssetTypes} from "constants/common_constants";
import { DataVirtual } from "assets/icons";
import { useDebouncedCallback } from "use-debounce";


type PaginationModel = {
    pageSize: number,
    page: number,
};

const DataAssetsTable: React.FC = () => {
    const {isEditMode, selectedGitBranch} = useGlobalContext();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const PAGE_SIZE = 20;
    const columns = [
        {
            field: "nameSchema",
            headerName: "Name (db.schema.name)",
            flex: 1,
            sortable: true,
            renderCell: (params: GridRenderCellParams) => (
                <Link to={`/data-assets/${params.row.id.toLowerCase()}`}
                      className="link-no-decoration"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px"
                    }}>
                    { params.row.type == DataAssetTypes.virtual_asset ? 
                        <span style={{marginRight: "-8px", display: "flex"}}><DataVirtual /></span> : 
                        (
                            <i className="material-symbols-outlined modal-icon-data" style={{textDecoration: "none"}}>
                                database
                            </i>
                        )
                    }
                    <div className="underline">
                        {params.value}
                    </div>
                </Link>
            ),
        },
        {
            field: "dbSchema",
            headerName: "db.schema",
            flex: 1,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <Chip
                    className="asset-title-chip data-asset-name-db"
                    label={params.value.toLowerCase()}
                    color="primary"
                    variant="outlined"
                />
            ),
        },
        {
            field: "key",
            headerName: "Key",
            flex: 1,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                const businessKeys = params.value?.split(", ") || [];
                return (
                    <Box className="business-keys-container" sx={{display: "flex", gap: "12px"}}>
                        {businessKeys.map((key: string, index: number) => (
                            <Chip
                                key={index}
                                className="asset-title-chip data-asset-cell-text"
                                label={key.toLowerCase()}
                                color="primary"
                                variant="outlined"
                            />
                        ))}
                    </Box>
                );
            },
        }
    ];
    const apiRef = useGridApiRef();
    const [dataAssets, setDataAssets] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [paginationModel, setPaginationModel] = useState<PaginationModel>({
        pageSize: PAGE_SIZE,
        page: 0,
    });

    const fetchData = async (searchValue: string, paginationModel: PaginationModel): Promise<void> => {
        const params: PaginationParams = {
            page: paginationModel.page === 0 ? 1 : paginationModel.page + 1, // DataGrid uses 0-based page count
            pageSize: paginationModel.pageSize,
            tableId: searchValue,
        };
        setIsLoading(true);
        try {
            const response: DataAssetsPaginationResponse = await getDataAssets(params, isEditMode ? selectedGitBranch : null);
            const assets = Object.values(response.assets).map(
                (asset) => ({
                    "id": asset.id,
                    "type": asset.type,
                    "nameSchema": asset.name,
                    "dbSchema": asset.db ? `${asset.db}.${asset.schema}` : asset.schema,
                    ...asset.keys && {"key": Object.values(asset.keys).join(", ")},
                    ...asset.businessKeys && {"businessKey": Object.values(asset.businessKeys).join(", ")},
                }));
            setDataAssets(assets);
            apiRef.current.setRowCount(response["totalRecords"]);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };
    const filterDebounce = useDebouncedCallback(fetchData, 300)

    useEffect(() => {
        filterDebounce(searchValue, paginationModel);
    }, [searchValue, paginationModel]);

    return (
        <Box className="flex-box-start" flexDirection="column">
            <Box style={{width: "100%"}} margin="24px 0 25px 0">

                <SearchComponent
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    sx={{width: "360px"}}
                />

                <DataGrid
                    apiRef={apiRef}
                    className="entity-data-grid assets-grid"
                    rows={dataAssets}
                    columns={columns}
                    paginationMode="server"
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    autoHeight
                    loading={isLoading}
                    initialState={{
                        sorting: {
                            sortModel: [{field: "nameSchema", sort: "asc"}],
                        },
                    }}
                    slots={{
                        pagination: CustomTablePagination,
                    }}
                    sx={{marginTop: "24px"}}
                />
            </Box>
        </Box>
    );
};

export default DataAssetsTable;
