import {Box, Button, FormControl, Modal, TextField, Typography} from "@mui/material";
import {AxiosError} from "axios";
import ButtonComponent from "components/shared/button/button.component";
import React from "react";
import {Controller, useForm} from "react-hook-form";
import {createSystemContext} from "services/system";
import theme from "theme";

interface modalProps {
    open: boolean;
    onClose: (shouldRefresh: boolean) => void;
}

interface FormField {
    id: number;
    label: string;
    placeholder: string;
    required: boolean;
    name: string;
    render?: (formField: FormField, field: any) => JSX.Element
}

const ContextModal: React.FC<modalProps> = ({
    open,
    onClose,
}) => {
    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        formState: {errors, isValid},
    } = useForm({mode: "onChange"});

    const formFields: FormField[] = [
        {
            id: 1,
            label: "Name *",
            placeholder: "Context Name (must be unique)...",
            required: true,
            name: "name",
            render: (formField: FormField, field) => (
                <TextField
                    className="measures-input"
                    {...field}
                    placeholder={formField.placeholder}
                    error={!!errors[formField.name]}
                    helperText={
                        errors[formField.name]
                            ? errors[formField.name]?.message
                            : ""
                    }
                />
            )
        },
        {
            id: 2,
            label: "Description",
            placeholder: "Context description...",
            required: false,
            name: "description",
            render: (formField: FormField, field) => (
                <TextField
                    className="measures-input"
                    {...field}
                    placeholder={formField.placeholder}
                    multiline
                    minRows={4}
                    error={!!errors[formField.name]}
                    helperText={
                        errors[formField.name]
                            ? errors[formField.name]?.message
                            : ""
                    }
                />
            )
        },
    ];

    const onSubmit = async (data: any) => {
        clearErrors('name');
        try {
            const response = await createSystemContext(data.name, data.description);
            onClose(true);
        } catch (error: any) {
            const errorMessage = error.message;
            setError('name', { type: 'custom', message: errorMessage });
        }
    };

    return (
        <Modal
            key="context-modal"
            open={open}
            onClose={() => onClose(false)}
        >
            <Box className="container-modal-small">
                <Button className="modal-close" onClick={() => onClose(false)}>
                    <span className="material-symbols-outlined modal-close-icon">
                        close
                    </span>
                </Button>

                <Box>
                    <Box className="flex-box-align-center">
                        <Typography
                            variant="h4"
                            sx={{width: "100%", textAlign: "center", fontWeight: 600}}
                        >
                            Create Context
                        </Typography>
                    </Box>
                </Box>

                <Box className="flex-box-col-start">
                    <Box
                        component="form"
                        onSubmit={handleSubmit(onSubmit)}
                        className="flex-box-col-start"
                        sx={{width: "100%"}}
                    >
                        {formFields.map((formField) => (
                            <FormControl key={formField.id} sx={{width: "100%", marginBottom: "24px"}}>
                                <Typography
                                    variant="h5"
                                    lineHeight="22px"
                                    mb="10px"
                                    sx={{color: theme.palette.customColor.darkGrey}}
                                >
                                    {formField.label}
                                </Typography>
                                <Controller
                                    name={formField.name}
                                    control={control}
                                    rules={{required: formField.required}}
                                    render={({field}) => {
                                        return formField.render ?
                                            formField.render(formField, field) :
                                            (<TextField
                                                className="measures-input"
                                                {...field}
                                                placeholder={formField.placeholder}
                                                error={!!errors[formField.name]}
                                                helperText={
                                                    errors[formField.name]
                                                        ? `${formField.label} is required`
                                                        : ""
                                                }
                                            />);
                                    }}
                                />
                            </FormControl>
                        ))}
                        <Box className="flex-box-end" sx={{width: "100%", marginTop: "8px"}}>
                            <ButtonComponent
                                type="button"
                                variant="text"
                                label="Cancel"
                                onClick={() => onClose(false)}
                                sx={{fontSize: "14px", lineHeight: "22px"}}
                            />
                            <ButtonComponent
                                type="submit"
                                disabled={!isValid}
                                variant="contained"
                                label="Create"
                                onClick={() => {}}
                                sx={{fontSize: "14px", lineHeight: "22px"}}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default ContextModal;