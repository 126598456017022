import React from "react";
import {Box, Container} from "@mui/material";
import Header from "components/header/header.component";
import {Outlet} from "react-router-dom";
import {useGlobalContext} from "../../context/global-context";
import BusinessEntitiesEditMode
    from "../../components/business-entities-component/business-entities-edit-mode/business-entities-edit-mode.component";


const Layout = () => {
    const {isEditMode, drawerOpen} = useGlobalContext();
    const marginLeft = drawerOpen ? "125px" : "10px"
    const marginTop = isEditMode ? "40px" : "10px"
    return (
        <>
            {isEditMode && <BusinessEntitiesEditMode/>}
            <Container
                sx={{
                    marginTop: marginTop,
                    marginLeft: marginLeft,
                    width: `calc(100% - ${marginLeft})`,
                    transition: "width 0.3s, margin 0.3s",
                    minHeight: `calc(100vh - ${marginTop})`,
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <Header/>
                <Box sx={{
                    padding: "41px 50px 0",
                    display: "flex",
                    flexGrow: 1
                }}>
                    <Outlet/>
                </Box>
            </Container>
        </>
    );
};

export default Layout;
