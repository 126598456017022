import {Box, Typography} from "@mui/material";
import {ArrowLeftSymbols} from "assets/icons";
import {FirstLastType} from "components/add-features-component/feature-types";
import ButtonComponent from "components/shared/button/button.component";
import SqlView from "components/shared/sql-view/sql-view.component";
import {useGlobalContext} from "context/global-context";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {DataAssetFullResponse, getDataAssetById} from "services/data-assets";
import {preview, TemplateType} from "services/templates";
import theme from "theme";
import {useDebouncedCallback} from "use-debounce";
import {DataAssetName} from "../../data-asset-name";
import AddFeaturesFirstLastModalContent from "./add-features-first-last-modal-content";
import RelatedAssetsList, { RelatedAssetsListEntitiesRelationFilter } from "./related-assets-list";

interface AddFeaturesModalDataProps {
    selectedAsset: DataAssetFullResponse | null;
    searchValue: string;
    setSelectedAsset: (asset: DataAssetFullResponse | null) => void;
    handleCloseModal: () => void;
}

const AddFeaturesFirstLastModalData: React.FC<AddFeaturesModalDataProps> = ({
    selectedAsset,
    searchValue,
    setSelectedAsset,
    handleCloseModal
}) => {
    const { entityId } = useParams<{ entityId: string }>();
    const { selectedGitBranch } = useGlobalContext();
    const [sql, setSql] = useState<string>("");

    const fetchDataAsset = async (assetId: string) => {
        try {
            const response = await getDataAssetById(assetId, selectedGitBranch);
            setSelectedAsset(response);
        } catch (error: any) {
            console.log(error.message);
        }
    };

    const onDataChanged = async (data: FirstLastType) => {
        const { sql: newSql } = await preview(selectedGitBranch, TemplateType.firstLast, {
            entity_id: entityId as string,
            asset_id: data.dataAssetId,
            filters: (data.filters || []).map(f => ({
                field: f.name,
                operator: f.operator,
                values: f.values,
                data_type: f.dataType
            })),
            method: data.method || "first",
            sort_by: data.sortBy || "",
            fields: data.fields.map(field => ({ field: field.name, name: field.alias || field.name }))
        });
        setSql(newSql);
    };
    const debouncedOnDataChanged = useDebouncedCallback(onDataChanged, 300);

    if (selectedAsset) {
        return (
            <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
                <Box sx={{ width: "450px", flexShrink: 0 }}>
                    <SqlView sql={sql} />
                </Box>
                <Box sx={{ flexGrow: 1, overflow: "auto", width: "100%" }}>
                    <>
                        <Typography variant="subtitle2" sx={{color: theme.palette.customColor.darkGrey}}>
                            Selected Data Asset
                        </Typography>
                        <DataAssetName assetId={selectedAsset.id} />
                    </>

                    <ButtonComponent
                        className="select-button-back"
                        variant="text"
                        label={<ArrowLeftSymbols />}
                        onClick={() => setSelectedAsset(null)}
                    />

                    <AddFeaturesFirstLastModalContent
                        selectedAsset={selectedAsset}
                        onChange={debouncedOnDataChanged}
                        onClose={handleCloseModal}
                    />
                </Box>
            </Box>
        );
    }

    return (
        <RelatedAssetsList
            entityId={entityId as string}
            filter={searchValue}
            relatedEntitiesRelationFilter={RelatedAssetsListEntitiesRelationFilter.AnyToMany}
            onSelectAsset={fetchDataAsset}
        />
    );
};

export default AddFeaturesFirstLastModalData;