import {FieldType} from "../components/add-features-component/feature-types";

export type FieldAction =
    | { type: "CREATE"; payload: FieldType }
    | { type: "DELETE"; payload: { id: string } }
    | { type: "DELETE_ALL" };

export const createFieldFeature = (field: FieldType): FieldAction => ({
    type: "CREATE",
    payload: {id: `fieldFeature-${Date.now()}`, ...field}, // using current timestamp as a unique id
});

export const deleteFieldFeature = (id: string): FieldAction => ({
    type: "DELETE",
    payload: { id },
});

export const deleteAllFieldFeatures = (): FieldAction => ({
    type: "DELETE_ALL"
});
