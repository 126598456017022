import {Box, CircularProgress, Typography} from "@mui/material";
import {DataGrid, GridColDef, GridToolbar} from "@mui/x-data-grid";
import EntityToAssetRelations
    from "components/add-edit-business-entity-component/add-edit-business-entities-related-datasets/entity-asset-relation-wrapper.component";
import React, {useEffect, useState} from "react";
import {useFieldArray, useForm} from "react-hook-form";
import {DataAssetResponse, getDataAssetById} from "services/data-assets";
import {RelationKind} from "services/entities";
import theme from "theme";
import ButtonComponent from "../../../shared/button/button.component";
import AddBusinessEntitiesRelatedDatasetsSelect
    from "../add-edit-business-entities-related-datasets-select/add-edit-business-entities-related-datasets-select.component";
import {EntityAssetRelation, RelatedDataAsset} from "../models";
import "./add-edit-business-entities-related-datasets-table-first.component.scss";

const LOADER_SIZE = 30

export interface RelatedAssetsTableData {
    assets: RelatedDataAsset[];
}

interface RelatedAssetsTableProps {
    entityFields: string[];
    initialValues: RelatedAssetsTableData;
    onChange: (value: RelatedAssetsTableData) => void;
    entityName: string;
}


const AddEditBusinessEntitiesRelatedDatasetsTableFirst: React.FC<RelatedAssetsTableProps> = ({
entityFields,
initialValues,
onChange,
entityName,
}) => {
    const getNewAsset = (index: number, assetId: string | null = null) : RelatedDataAsset =>{
        return {
            id: index, 
            assetId: assetId,
            asset: null,
            assetFields: []
        }
    }
    
    const [idCounter, setIdCounter] = useState<number>(initialValues?.assets?.length || 0);
    const {
        control,
        formState: {errors},
        getValues,
    } = useForm({
        defaultValues: initialValues || {
            assets: [getNewAsset(idCounter)]
        }
    });
    const {fields: assets, append: addAsset, remove: removeAsset, update: updateAsset} = useFieldArray({
        control,
        name: "assets"
    });

    const updateParent = () => {
        onChange(getValues());
    };

    const handleAddClick = () => {
        addAsset(getNewAsset(idCounter + 1));
        setIdCounter(idCounter + 1);
        updateParent();
    };

    const getRowIndex = (relatedAsset: RelatedDataAsset): number => {
        return getValues("assets").findIndex(asset => asset.id === relatedAsset.id);
    };

    const handleDeleteClick = (relatedAsset: RelatedDataAsset) => {
        const index = getRowIndex(relatedAsset);
        if (index == -1) 
            return;
        removeAsset(index);
        updateParent();
    };

    const fetchDataAsset = async (assetId: string) => {
        try {
            return await getDataAssetById(assetId);
        } catch (error: any) {
            console.log(error.message);
        }
    };

    useEffect(()=>{
        const getInitialData = async () => {
            const initialAssets = getValues("assets")
            
            const assets = await Promise.all(initialAssets.map(i=> i.assetId ? fetchDataAsset(i.assetId) : Promise.resolve()))
            assets.forEach((asset,i) => {
                if (asset)
                    updateAsset(i, {
                        ...getValues("assets")[i],
                        asset: asset
                    });
            })
        };
        getInitialData()
    },[])

    const onAssetChanged = async (relatedAsset: RelatedDataAsset, selectedAsset: DataAssetResponse | null) => {
        if (!selectedAsset) return;

        const index = getRowIndex(relatedAsset);
        if (index == -1) 
            return

        const newAsset = getNewAsset(index, selectedAsset.id);
        updateAsset(index, newAsset);
        
        newAsset.asset = await fetchDataAsset(selectedAsset.id);
        newAsset.assetFields = newAsset.asset?.columns?.map(c => c.name) || [];
        updateAsset(index, newAsset);
        updateParent();
    };

    const onRelationshipChanged = async (
        relatedAsset: RelatedDataAsset,
        relationKind: RelatedDataAsset["relationKind"],
        relations: RelatedDataAsset["entityAssetRelations"] | RelatedDataAsset["sql"]
    ) => {
        const index = getRowIndex(relatedAsset);
        if (index == -1)
            return

        if (relationKind === RelationKind.fields) {
            updateAsset(index, {
                ...getValues("assets")[index],
                relationKind,
                entityAssetRelations: relations as RelatedDataAsset["entityAssetRelations"]
            });
        }
        else if (relationKind === RelationKind.sql) {
            updateAsset(index, {
                ...getValues("assets")[index],
                relationKind,
                sql: relations as RelatedDataAsset["sql"]
            });
        }
        updateParent()
    };

    const getTableKeyColumn = (relatedAsset: RelatedDataAsset): string => {
        if (!relatedAsset.asset && !relatedAsset.assetId) return "Choose data asset first";

        return relatedAsset.asset?.keys && relatedAsset.asset.keys.filter(a => a).length ? relatedAsset.asset.keys.join(", ") : "-";
    };

    const columns: GridColDef[] = [
        {
            field: "nameData",
            headerName: "Name",
            flex: 1.4,
            sortable: false,
            renderCell: (params) => {
                return (
                    <AddBusinessEntitiesRelatedDatasetsSelect
                        onChange={(_e, newValue) => onAssetChanged(params.row, newValue)}
                        name={`table_pic_${params.row.id}`}
                        initialValue={params.row.assetId}
                    />
                );
            },
        },
        {
            field: "key",
            headerName: "Key",
            flex: 0.9,
            sortable: false,
            renderCell: (params) => (
                <Box>
                    {(
                        (!params.row.asset && !params.row.assetId) || 
                        (params.row.asset && params.row.assetId) ? (
                            <Typography
                                className={params.row.asset && params.row.assetId ? "" : "dim-table-column-sec"}
                                variant="h5"
                                sx={{
                                    color: params.row.asset ? theme.palette.customColor.dark : theme.palette.customColor.lavenderGrey,
                                    lineHeight: "22px",
                                }}
                                >
                                {getTableKeyColumn(params.row)}
                            </Typography>
                        ) : 
                            <Box sx={{textAlign: "center"}}><CircularProgress size={LOADER_SIZE} color="inherit"/></Box>
                    )}
                </Box>
            ),
        },
        {
            field: "entityAssetRelations",
            headerName: "Relation (<entity>)",
            flex: 0.9,
            sortable: false,
            renderCell: (params) => (
                <Box>
                    {!params.row.asset && !params.row.assetId ?
                        <Typography
                            className="dim-table-column-sec"
                            variant="h5"
                            sx={{
                                color: params.row.asset ? theme.palette.customColor.dark : theme.palette.customColor.lavenderGrey,
                                lineHeight: "22px",
                            }}
                        >
                            {getTableKeyColumn(params.row)}
                        </Typography>
                        : (!params.row.asset && params.row.assetId) ? (
                            <Box sx={{textAlign: "center"}}><CircularProgress size={LOADER_SIZE} color="inherit"/></Box>
                        ) : (
                            <EntityToAssetRelations
                                entityFields={entityFields}
                                entityName={entityName}
                                dataAsset={params.row.asset}
                                relationKind={params.row.relationKind}
                                sql={params.row.sql}
                                entityAssetRelations={params.row.entityAssetRelations}
                                setFieldsRelations={
                                    (relations: EntityAssetRelation[]) =>
                                        onRelationshipChanged(params.row, RelationKind.fields, relations)}
                                setSqlRelations={
                                    (sql: RelatedDataAsset["sql"]) =>
                                        onRelationshipChanged(params.row, RelationKind.sql, sql)}
                            />
                        )
                    }
                </Box>),
        },
        {
            field: "deleteAction",
            cellClassName: "asset-delete-btn-wrapper",
            headerName: "",
            width: 30,
            sortable: false,
            renderCell: (params) => (
                <span
                    className="material-symbols-outlined"
                    style={{cursor: "pointer"}}
                    onClick={() => handleDeleteClick(params.row)}
                >
                    delete
                </span>
            ),
        },
    ];

    return (
        <Box className="flex-box-col-center" sx={{maxWidth: "1005px", width: "100%"}}>
            <Box className="dim-table-style" sx={{width: "100%", marginTop: "32px"}}>
                {assets.length > 0 && (
                    <DataGrid
                        autoHeight={true}
                        className="entity-data-grid"
                        columns={columns}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableAutosize={false}
                        getRowHeight={() => "auto"}
                        hideFooter={true}
                        initialState={{
                            sorting: {
                                sortModel: [{field: "nameData", sort: "asc"}],
                            },
                        }}
                        rows={getValues("assets")}
                        sx={{
                            margin: "16px 0 0 0",
                            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {py: "15px"},
                        }}
                        slots={{toolbar: GridToolbar}}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: false,
                            },
                        }}
                    />
                )}
                <ButtonComponent
                    onClick={handleAddClick}
                    variant="textPurple"
                    label="+ Add new"
                    sx={{fontSize: "14px", marginTop: "16px", paddingLeft: 0}}
                />
            </Box>
        </Box>
    );
};

export default AddEditBusinessEntitiesRelatedDatasetsTableFirst;
