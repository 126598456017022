import {Box} from "@mui/material";
import React from "react";
import {DataAssetFullResponse, DataAssetResponse} from "services/data-assets";
import AssetTitle from "../shared/assets/asset-title/asset-title.component";
import AssetVirtualTitle from "../shared/assets/asset-title/asset-virtual-title.component";

export const getDataAssetName = (asset: DataAssetResponse | DataAssetFullResponse): string => {
    if (asset["db"] && asset["schema"]) {
        return `${asset["db"]}.${asset["schema"]}.${asset["name"]}`.toLowerCase();
    }
    return asset["name"].toLowerCase();
}

export const getDataAssetTitle = (assetId: string, chipOnly: boolean = false) =>
    <AssetTitle assetId={assetId} chipOnly={chipOnly} />;

export const getDataAssetVirtualTitle = (asset: DataAssetResponse | DataAssetFullResponse) =>
    <AssetVirtualTitle assetId={asset.id} />;

interface DataAssetNameProps {
    assetId: DataAssetResponse["id"];
}

export const DataAssetName: React.FC<DataAssetNameProps> = ({assetId}) => {
    return (
        <Box className="flex-box-align-center select-text-step" sx={{mt: "10px"}}>
            <span className="material-symbols-outlined modal-icon-data">
                database
            </span>
            {getDataAssetTitle(assetId)}
        </Box>
    )
}