import ContextModal from "components/admin-component/account/contexts-table/context-create-modal";
import {DEFAULT_CONTEXT, useGlobalContext} from "context/global-context";
import React, {useCallback, useEffect, useState} from "react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import {deleteSystemContext, getSystemContexts} from "services/system";
import ButtonComponent from "../../../shared/button/button.component";
import { Box, Typography } from "@mui/material";
import theme from "theme";
import ConfirmationModal from "components/shared/modal/confirmation-modal";


type ContextRow = {
    id: string,
    name: string,
    description?: string
};

const ContextsTable = () => {
    const { contexts, setContexts } = useGlobalContext();
    const [openContextModal, setContextDataOpen] = useState<boolean>(false);
    const [contextToDelete, setContextToDelete] = useState<string | null>(null);
    const [contextData, setContextData] = useState<ContextRow[]>([]);

    const fetchData = useCallback(async () => {
        const data = await getSystemContexts();
        setContexts(data)
    }, []);

    useEffect(() => {
        setContextData(contexts.map(context => ({id: context.name, name: context.name, description: context.description || ""})));
    }, [contexts]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const deleteContext = async ()=>{
        if (!contextToDelete) 
            return
        await deleteSystemContext(contextToDelete);
        setContextToDelete(null);
        fetchData();
    }

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Name",
            flex: .9,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: theme.palette.customColor.dark,
                        }}
                    >
                        {params.row.name}
                    </Typography>
                );
            },
        },
        {
            field: "description",
            headerName: "Description",
            flex: .9,
            sortable: false,
            renderCell: (params) => (
                <Typography
                    variant="h5"
                    sx={{
                        color: theme.palette.customColor.slateGrey,
                    }}
                >
                    {params.row.description}
                </Typography>
            ),
        },
        {
            field: "deleteAction",
            headerName: "",
            width: 50,
            sortable: false,
            renderCell: (params) => {
                return params.row.name !== DEFAULT_CONTEXT
                    ? (<span
                        className="material-symbols-outlined flex-box-center"
                        style={{cursor: "pointer", width: "100%", color: theme.palette.customColor.slateGrey}}
                        onClick={()=>setContextToDelete(params.row.name)}
                    >
                        delete
                    </span>)
                    : null
            }
        },
    ];

    return (
        <Box className="dim-table-style" sx={{ width: "100%", marginTop: "32px" }}>
            <DataGrid
                autoHeight={true}
                className="entity-data-grid"
                columns={columns}
                rows={contextData}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                getRowHeight={() => "auto"}
                hideFooter={true}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "name", sort: "asc" }],
                    },
                }}
                sx={{
                    margin: "16px 0 0 0",
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "15px" },
                }}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: false,
                    },
                }}
            />
            <ButtonComponent
                onClick={() => setContextDataOpen(true)}
                variant="textPurple"
                label="+ Add context"
                sx={{ fontSize: "16px", marginTop: "4px", paddingLeft: 0 }}
            />

            <ConfirmationModal
                open={Boolean(contextToDelete)}
                onClose={()=>setContextToDelete(null)}
                onConfirm={()=>deleteContext()}
                title={`Are you sure you want to delete context '${contextToDelete}'?`}
                body={`This action will permanently delete the context, including features and data assets measures which were created on this context.`}
                confirmText="Delete"
                cancelText="Cancel"
            />
            
            <ContextModal
                open={openContextModal}
                onClose={(shouldRefresh: boolean) => {setContextDataOpen(false); shouldRefresh && fetchData();}} />
        </Box>
    );
};

export default ContextsTable;