import React, {createContext, useContext, useState, ReactNode, SetStateAction, Dispatch, useEffect} from "react";
import { SystemContext } from "services/system";

interface ContextValue {
    selectedGitBranch: string | null;
    setSelectedGitBranch: Dispatch<SetStateAction<string | null>>;
    isEditMode: boolean;
    toggleEditMode: Dispatch<SetStateAction<boolean>>;
    drawerOpen: boolean;
    setDrawerOpen: Dispatch<SetStateAction<boolean>>;
    isUserOnboarded: boolean;
    setUserOnboarded: Dispatch<SetStateAction<boolean>>;
    selectedContext: string;
    setSelectedContext: Dispatch<SetStateAction<string>>;
    contexts: SystemContext[]
    setContexts: Dispatch<SetStateAction<SystemContext[]>>;
}

export const DEFAULT_CONTEXT = "default";

const Context = createContext<ContextValue | undefined>(undefined);

const GlobalContextProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [selectedGitBranch, setSelectedGitBranch] = useState<string | null>(() => {
        const storedBranch = localStorage.getItem("selectedGitBranch");
        return storedBranch ? JSON.parse(storedBranch) : null;
    });

    const [contexts, setContexts] = useState<SystemContext[]>([{name: DEFAULT_CONTEXT}]);

    const [isEditMode, setIsEditMode] = useState<boolean>(() => {
        const storedIsEditMode = localStorage.getItem("isEditMode");
        return storedIsEditMode ? JSON.parse(storedIsEditMode) : false;
    });

    const [drawerOpen, setDrawerOpen] = useState<boolean>(() => {
        const storedDrawerOpen = localStorage.getItem("drawerOpen");
        return storedDrawerOpen ? JSON.parse(storedDrawerOpen) : false;
    });

    const [isUserOnboarded, setUserOnboarded] = useState<boolean>(() => {
        const storedIsUserOnboarded = localStorage.getItem("isUserOnboarded");
        return storedIsUserOnboarded ? JSON.parse(storedIsUserOnboarded) : false;
    });

    const [selectedContext, setSelectedContext] = useState<string>(() => {
        return localStorage.getItem("selectedContext") || DEFAULT_CONTEXT;
    });

    useEffect(() => {
        localStorage.setItem("selectedGitBranch", JSON.stringify(selectedGitBranch));
    }, [selectedGitBranch]);

    useEffect(() => {
        localStorage.setItem("isEditMode", JSON.stringify(isEditMode));
    }, [isEditMode]);

    useEffect(() => {
        localStorage.setItem("drawerOpen", JSON.stringify(drawerOpen));
    }, [drawerOpen]);

    useEffect(() => {
        localStorage.setItem("isUserOnboarded", JSON.stringify(isUserOnboarded));
    }, [isUserOnboarded]);

    useEffect(() => {
        localStorage.setItem("selectedContext", selectedContext);
    }, [selectedContext]);

    return (
        <Context.Provider value={{
            selectedGitBranch,
            setSelectedGitBranch,
            isEditMode,
            toggleEditMode: setIsEditMode,
            drawerOpen,
            setDrawerOpen,
            isUserOnboarded,
            setUserOnboarded,
            selectedContext,
            setSelectedContext,
            contexts,
            setContexts
        }}>
            {children}
        </Context.Provider>
    );
};

const useGlobalContext = (): ContextValue => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error("useGlobalContext must be used within a GlobalContextProvider");
    }
    return context;
};

export {GlobalContextProvider, useGlobalContext};
