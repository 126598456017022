import axiosInstance from "../utils/axios";
import {AxiosResponse} from "axios";

export interface GitIntegrationPayload {
    authType: string;
    repoUrl: string;
    username?: string;
    authCred: string;
}

export const connectGit = async (payload: GitIntegrationPayload) => {
    const requestData = {
        auth_type: payload.authType,
        repo_url: payload.repoUrl,
        auth_cred: payload.authCred,
        ...(payload.username && {username: payload.username})
    };
    const response = await axiosInstance.put("/integrations/git", {...requestData});
    return response.data;
};

export interface SnowflakeIntegrationPayload {
    account: string;
    user: string;
    password: string;
    role: string;
    warehouse: string;
}

export const connectSnowflake = async (payload: SnowflakeIntegrationPayload): Promise<AxiosResponse["data"]> => {
    const response = await axiosInstance.put(
        "/integrations/data-connector",
        payload,
        {params: {connector_name: "snowflake"}}
    );
    return response.data;
};

export interface SnowflakeSchemasPayload {
    schemas: string[];
}

export const connectSnowflakeSchemas = async (
    payload: SnowflakeSchemasPayload
): Promise<AxiosResponse["data"]> => {
    const response: AxiosResponse = await axiosInstance.post(
        "/integrations/data-connector",
        payload,
        {params: {connector_name: "snowflake"}}
    );
    return response.data;
};

export const getSnowflakeSchemas = async (): Promise<AxiosResponse["data"]> => {
    const response: AxiosResponse = await axiosInstance.get(
        "/integrations/data-connector/schemas",
        {params: {connector_name: "snowflake"}}
    );
    return response.data;
};

export const discover = async (): Promise<AxiosResponse["data"]> => {
    const response: AxiosResponse = await axiosInstance.get("/discovery/initiate");
    return response.data;
};