import RootComponent from "page/root.component";
import React, {useEffect} from "react";
import {useAuth, useLoginWithRedirect, useTenantsState, useAuthUserOrNull} from "@frontegg/react";
import {Navigate, Route, Routes} from "react-router-dom";
import Onboarding from "../page/onboarding/onboarding";
import Layout from "../page/layout/layout";
import DataAssets from "../page/data-assets/data-assets";
import DataAsset from "../page/data-asset/data-asset";
import {useLoggedInUser} from "../context/logged-in-user-context";
import {saveLoggedInUser} from "../actions/user-reducer";
import BusinessEntitiesComponent from "./business-entities-component/business-entities.component";
import BusinessEntityComponent from "./business-entity-component/business-entity.component";
import AdminComponent from "./admin-component/admin-component";
import AdminAccountComponent from "./admin-component/account/account-component";

const App = () => {
    const {isAuthenticated} = useAuth();
    const user = useAuthUserOrNull();
    const tenant = useTenantsState();
    const loginWithRedirect = useLoginWithRedirect();
    const {dispatchUserAction} = useLoggedInUser();

    useEffect(() => {
        if (!isAuthenticated) {
            loginWithRedirect();
        }
    }, [isAuthenticated, loginWithRedirect]);

    useEffect(() => {
        if (isAuthenticated && user && tenant.activeTenant) {
            dispatchUserAction(
                saveLoggedInUser({
                    name: user.name,
                    profilePictureUrl: user.profilePictureUrl,
                    email: user.email,
                    organization: tenant.activeTenant.name,
                    tenantId: tenant.activeTenant.id
                })
            );
        }
    }, [isAuthenticated, user, tenant, dispatchUserAction]);

    return (
        isAuthenticated ? (
            <Routes>
                <Route path="/onboarding" element={<Onboarding/>}/>
                <Route element={<RootComponent/>}>
                    <Route element={<Layout/>}>
                        <Route path="/admin" element={<AdminComponent/>}>
                            <Route path="account" element={<AdminAccountComponent/>}/>
                        </Route>
                        <Route index element={<Navigate replace to="/entities"/>}/>
                        <Route path="/entities" element={<BusinessEntitiesComponent />}>
                            <Route path=":entityId" element={<BusinessEntityComponent />} />
                        </Route>
                        <Route path="/data-assets" element={<DataAssets/>}/>
                        <Route path="/data-assets/:assetId" element={<DataAsset/>}/>
                    </Route>
                </Route>
            </Routes>
        ) : (<></>)
    );
};

export default App;
