import React, {ChangeEvent, useEffect, useState} from "react";
import {
    Select,
    Typography,
    SelectProps as MuiSelectProps,
    MenuProps,
    ListSubheader,
    TextField,
    InputAdornment,
    CircularProgress,
    Box
} from "@mui/material";
import {ChevronDown} from "lucide-react";
import SearchIcon from "@mui/icons-material/Search";
import theme from "theme";
import {SelectChangeEvent} from "@mui/material/Select";
import { containsText } from "utils/contains-text";

interface SelectOption {
    title: string;
}

interface SelectComponentProps<T extends SelectOption> {
    options: T[];
    renderOption: (option: T) => React.ReactNode;
    placeholder?: string;
    menuProps?: MenuProps;
    value?: string;
    isLoading?: boolean;
    onChange: (event: SelectChangeEvent<string>) => void;
    showSearch?: boolean;
    paperProps?: React.CSSProperties;
    onSearchChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const SelectComponent = <T extends SelectOption>({
                                                     options,
                                                     renderOption,
                                                     placeholder = "Select",
                                                     menuProps,
                                                     value,
                                                     isLoading,
                                                     onChange,
                                                     showSearch = false,
                                                     paperProps,
                                                     onSearchChange,
                                                     ...props
                                                 }: SelectComponentProps<T> & Omit<MuiSelectProps<string>, "value" | "onChange">) => {
    const [searchText, setSearchText] = useState("");
    const [filteredOptions, setFilteredOptions] = useState<T[]>(options);

    useEffect(()=>{
      setFilteredOptions(options.filter((option) => containsText(option.title, searchText)))
    }, [searchText, options])

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
        if (onSearchChange) {
            onSearchChange(event);
        }
    };

    return (
        <Select
            variant="outlined"
            fullWidth
            displayEmpty
            onClose={()=>setSearchText("")}
            renderValue={(selected) =>
                selected ? selected : <Typography className="select-placeholder">{placeholder}</Typography>
            }
            IconComponent={ChevronDown}
            MenuProps={{
                autoFocus: false,
                PaperProps: {
                    sx: {
                        borderRadius: "16px",
                        border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                        background: theme.palette.customColor.white,
                        boxShadow: "0px 4px 13px 0px rgba(97, 97, 97, 0.02)",
                        marginTop: "11px",
                        maxHeight: "300px",
                        li: {
                            backgroundColor: "transparent !important",
                            color: theme.palette.customColor.darkGrey,
                            fontSize: "16px",
                            lineHeight: "24px",
                            padding: "10px 16px",
                            "&:hover": {
                                backgroundColor: "transparent",
                            },
                        },
                        ...paperProps,
                    },
                },
                ...menuProps
            }}
            value={value}
            onChange={onChange}
            {...props}
        >
            {showSearch && (
                <ListSubheader>
                    <TextField
                        className="search-input"
                        size="small"
                        placeholder="Search..."
                        fullWidth
                        autoFocus
                        sx={{
                            border: 0,
                            borderRadius: "16px",
                            backgroundColor: theme.palette.customColor.lightestGrey5,
                            boxShadow: "0 4px 13px 0 rgba(97, 97, 97, 0.02)",
                            paddingLeft: 0,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" className="select-search-icon">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                        onChange={handleSearchChange}
                        onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                                e.stopPropagation();
                            }
                        }}
                    />
                </ListSubheader>
            )}
            { isLoading ? (<Box sx={{textAlign: "center"}}><CircularProgress color="inherit"/></Box>) : (
                filteredOptions.map(renderOption)
            ) }
        </Select>
    );
};

export default SelectComponent;
