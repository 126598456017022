import React, { useState } from "react";
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    Typography,
    Modal,
    Button,
    Popover,
} from "@mui/material";
import theme from "theme";
import "./add-features-card.scss";
import AddFeaturesModal from "../add-features-modal";
import AddFeaturesFormula from "../add-features-modal-content/add-features-formula/add-features-formula";
import ButtonComponent from "components/shared/button/button.component";

interface AddFeaturesCardProps {
    featuresAdded: boolean;
}

const AddFeaturesCard: React.FC<AddFeaturesCardProps> = ({ featuresAdded }) => {
    const [openModal, setOpenModal] = useState<null | string>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const handleOpenModal = (title: string) => {
        setOpenModal(title);
    };

    const handleCloseModal = () => {
        setOpenModal(null);
    };

    const cards = [
        {
            title: "Field",
            description:
                "Enriching the entity with simple fields from other data assets or entities",
            icon: "linked_services",
            label: "for one-to-one relationships",
            modal: (
                <AddFeaturesModal
                    type="field"
                    handleCloseModal={handleCloseModal}
                />
            ),
        },
        {
            title: "Metric",
            description:
                "A Metric is a type of measure aggregation used in data analysis to quantify and evaluate specific aspects of a dataset",
            icon: "analytics",
            label: "for one-to-many relationships",
            modal: (
                <AddFeaturesModal
                    type="metric"
                    handleCloseModal={handleCloseModal}
                />
            ),
        },
        {
            title: "First / Last",
            description:
                "Apply measure aggregation to this Entity. Mostly used for quantifiable fields. for example: SUM(total_orders)",
            icon: "process_chart",
            label: "for one-to-many relationships",
            modal: (
                <AddFeaturesModal
                    type="first/last"
                    handleCloseModal={handleCloseModal}
                />
            ),
        },
        {
            title: "Formula",
            description:
                "A calculated field / composition of one or more features that were already defined on this entity",
            icon: "ƒ",
            label: null,
            modal: (
                <AddFeaturesFormula
                    handleCloseModal={handleCloseModal}
                />
            ),
        },
    ];

    return (
        <Box className="flex-box-space-between" sx={{ margin: "50px -8px" }}>
            {!featuresAdded && (
                <>
                    {cards.map((card) => (
                        <Card
                            key={card.title}
                            draggable={false}
                            sx={{
                                border: 0,
                                boxShadow: "none",
                                backgroundColor: "transparent",
                            }}
                        >
                            <CardActionArea
                                onClick={() => handleOpenModal(card.title)}
                                sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    boxSizing: "border-box",
                                    height: "297px",
                                    maxWidth: "296px",
                                    borderRadius: "16px",
                                    border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                                    backgroundColor: theme.palette.customColor.white,
                                    boxShadow: "0px 4px 13px 0px rgba(97, 97, 97, 0.02)",
                                    margin: "0 8px",
                                    padding: "32px",
                                }}
                            >
                                <CardContent
                                    className="flex-box-col-between"
                                    sx={{ height: "100%", padding: 0 }}
                                >
                                    <Box className="flex-box-col-center">
                                        <span
                                            className="material-symbols-outlined"
                                            style={{
                                                fontSize: "32px",
                                                color: theme.palette.customColor.purple,
                                            }}
                                        >
                                            {card.icon}
                                        </span>
                                        <Typography
                                            variant="subtitle2"
                                            textAlign="center"
                                            sx={{
                                                color: theme.palette.customColor.black,
                                                margin: "24px 0 10px 0",
                                            }}
                                        >
                                            {card.title}
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            textAlign="center"
                                            sx={{
                                                fontWeight: 400,
                                                color: theme.palette.customColor.grey,
                                            }}
                                        >
                                            {card.description}
                                        </Typography>
                                    </Box>
                                    {card.label && (
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                backgroundColor:
                                                theme.palette.customColor.lightestGrey1,
                                                borderRadius: "10px",
                                                textAlign: "center",
                                                color: theme.palette.customColor.grey,
                                                lineHeight: "20px",
                                                padding: "2px 6px",
                                            }}
                                        >
                                            {card.label}
                                        </Typography>
                                    )}
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    ))}
                </>
            )}
            {cards.map((card) => (
                <Modal
                    key={card.title}
                    open={openModal === card.title}
                    onClose={handleCloseModal}
                    aria-labelledby={`${card.title}-modal-title`}
                    aria-describedby={`${card.title}-modal-description`}
                    className="container-modal-bg"
                >
                    <Box
                        className="container-modal-small"
                        sx={{
                            minHeight: "80svh",
                            maxHeight: "80svh",
                            overflowY: "auto",
                            minWidth: "800px",
                            height: "100%",
                        }}
                    >
                        <Button className="modal-close" onClick={handleCloseModal}>
                            <span className="material-symbols-outlined modal-close-icon">
                                close
                            </span>
                        </Button>
                        {card.modal}
                    </Box>
                </Modal>
            ))}
            <Box>
                {featuresAdded && (
                    <ButtonComponent
                        onClick={handleOpenPopover}
                        variant="contained"
                        label="+ Add"
                    />
                )}
                <Popover
                    className="features-popover"
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    slotProps={{
                        paper: {
                            sx: {
                                maxWidth: "425px",
                                borderRadius: "16px",
                                border: ` 1px solid ${theme.palette.customColor.lightGrey1}`,
                                boxShadow: "0 4px 13px 0 rgba(97, 97, 97, 0.02)",
                                marginTop: "10px",
                                padding: "4px",
                            },
                        },
                    }}
                >
                    <Box className="add-features-popover-box">
                        {cards.map((card) => (
                            <Box
                                className="flex-box-align-center"
                                key={card.title}
                                sx={{
                                    padding: "12px",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    handleOpenModal(card.title);
                                    handleClosePopover();
                                }}
                            >
                                <span
                                    className="material-symbols-outlined"
                                    style={{
                                        fontSize: "24px",
                                        color: theme.palette.customColor.purple,
                                        marginRight: "16px",
                                    }}
                                >
                                    {card.icon}
                                </span>
                                <Box>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{ color: theme.palette.customColor.black }}
                                    >
                                        {card.title}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: theme.palette.customColor.grey }}
                                    >
                                        {card.description}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Popover>
            </Box>
        </Box>
    );
};

export default AddFeaturesCard;